<template>
    <div class="no_data">
        <img class="img" src="@/assets/images/nodata.png" alt="暂无数据">
        <p class="txt">{{text}}</p>
    </div>
</template>

<script>
export default {
    name: "noData",
    props: {
        text: {
            type: String,
            default: "暂时还没有数据哦",
        },
    },
};
</script>
<style lang="less" scoped>
.no_data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80%;
    .img {
        display: block;
        max-width: 90%;
    }
    .txt {
        margin: 10px 0 0;
        color: #999;
    }
}
</style>