<template>
    <div class="left_nav">
        <div class="nav_list" v-if="fromType == 'form'">
            <router-link class="item" :to="{path: '/template', query: {fromType }}" v-if='identityType != "admin"'>
                <span class="ico_wrap">
                    <i class="iconfont icon-mobanguanli"></i>
                </span>
                <span class="txt">模板</span>
            </router-link>
            <router-link class="item" to="/questionTypes">
                <span class="ico_wrap">
                    <i class="iconfont icon-leixing"></i>
                </span>
                <span class="txt">题型</span>
            </router-link>
            <router-link class="item" to="/questionBank">
                <span class="ico_wrap">
                    <i class="iconfont icon-tiqiatiku"></i>
                </span>
                <span class="txt">题库</span>
            </router-link>
            <router-link class="item" :to="{path:'/share', query: {fromType }}" v-if='identityType != "admin" && identityType != "user"'>
                <span class="ico_wrap">
                    <i class="iconfont icon-fenxiang_2"></i>
                </span>
                <span class="txt">分享</span>
            </router-link>
            <router-link class="item" :to="{path: '/set', query: {fromType }}" v-if='identityType != "admin" && identityType != "user"'>
                <span class="ico_wrap">
                    <i class="iconfont icon-shezhi"></i>
                </span>
                <span class="txt">设置</span>
            </router-link>
        </div>
        <div class="nav_list" v-else-if="fromType == 'exam'">
            <router-link class="item" :to="{path: '/template', query: {fromType }}" v-if='identityType != "admin"'>
                <span class="ico_wrap">
                    <i class="iconfont icon-mobanguanli"></i>
                </span>
                <span class="txt">模板</span>
            </router-link>
            <router-link class="item" :to="{path:'/exam_questionTypes'}">
                <span class="ico_wrap">
                    <i class="iconfont icon-leixing"></i>
                </span>
                <span class="txt">题型</span>
            </router-link>
            <router-link class="item" :to="{path:'/exam_questions'}">
                <span class="ico_wrap">
                    <i class="iconfont icon-tiqiatiku"></i>
                </span>
                <span class="txt">题库</span>
            </router-link>
            <router-link class="item" :to="{path:'/share', query: {fromType }}" v-if='identityType != "admin" && identityType != "user"'>
                <span class="ico_wrap">
                    <i class="iconfont icon-fenxiang_2"></i>
                </span>
                <span class="txt">分享</span>
            </router-link>
            <router-link class="item" :to="{path: '/set', query: {fromType }}" v-if='identityType != "admin" && identityType != "user"'>
                <span class="ico_wrap">
                    <i class="iconfont icon-shezhi"></i>
                </span>
                <span class="txt">设置</span>
            </router-link>
        </div>
        <div class="nav_list" v-else>
            <router-link class="item" :to="{path: '/template', query: {fromType }}" v-if='identityType != "admin"'>
                <span class="ico_wrap">
                    <i class="iconfont icon-mobanguanli"></i>
                </span>
                <span class="txt">模板</span>
            </router-link>
            <router-link class="item" :to="{path:'/ques_questionTypes'}">
                <span class="ico_wrap">
                    <i class="iconfont icon-leixing"></i>
                </span>
                <span class="txt">题型</span>
            </router-link>
            <router-link class="item" :to="{path:'/ques_questions'}">
                <span class="ico_wrap">
                    <i class="iconfont icon-tiqiatiku"></i>
                </span>
                <span class="txt">题库</span>
            </router-link>
            <router-link class="item" :to="{path:'/share', query: {fromType }}" v-if='identityType != "admin" && identityType != "user"'>
                <span class="ico_wrap">
                    <i class="iconfont icon-fenxiang_2"></i>
                </span>
                <span class="txt">分享</span>
            </router-link>
            <router-link class="item" :to="{path: '/set', query: {fromType }}" v-if='identityType != "admin" && identityType != "user"'>
                <span class="ico_wrap">
                    <i class="iconfont icon-shezhi"></i>
                </span>
                <span class="txt">设置</span>
            </router-link>
        </div>
    </div>
</template>
<script>
export default {
    name: "designLeftNav",
    props: {
        fromType:{
            type:String
        },
        identityType:{
            type:String
        },
    },
    data(){
		return {
            
		}
	},
    watch:{
    }
};
</script>
<style lang="less" scoped>
.left_nav {
    .nav_list {
        width: 60px;
        height: 100%;
        padding: 20px 0 0;
        background-color: #282b33;
        overflow-y: auto;
        .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 8px 0;
            .ico_wrap {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 36px;
                height: 36px;
                border-radius: 4px;
                .iconfont {
                    font-size: 20px;
                    color: #999;
                }
                .icon-tiqiatiku {
                    font-size: 18px;
                }
            }
            .txt {
                line-height: 28px;
                font-size: 12px;
                color: #bfc0c2;
            }
            &:hover {
                .txt {
                    color: #fff;
                }
                .ico_wrap {
                    background-color: var(--theme-color);
                    .iconfont {
                        color: #fff;
                    }
                }
            }
        }
        .router-link-exact-active,.router-link-active {
            .txt {
                color: #fff;
            }
            .ico_wrap {
                background-color: var(--theme-color);
                .iconfont {
                    color: #fff;
                }
            }
        }
    }
}
</style>