import axios from "axios";
import qs from 'qs';
import route from '../router'
let router = import("@/router");
axios.defaults.baseURL = "";
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
axios.defaults.headers["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers["Cache-Control"] = "no-cache";
axios.defaults.headers["pragma"] = "no-cache";
let source = axios.CancelToken.source();
//请求添加token
axios.interceptors.request.use(request => {
  const token = sessionStorage.getItem('token');
  request.headers["Authorization"] = "Form Bearer " + token;
  return request;
})

//切换页面取消请求
axios.interceptors.request.use(request => {
  request.cancelToken = source.token;
  return request;
});
router.then(lib => {
  lib.default.beforeEach((to, from, next) => {
    source.cancel()
    source = axios.CancelToken.source();
    next()
  })
})
//返回值解构
axios.interceptors.response.use(response => {
  let data = response.data;
  let isJson = (response.headers["content-type"] || "").includes("json");
  if (isJson) {
    if (data.code == '200') {
      return Promise.resolve({
        data: data.data,
        msg: data.msg,
        code: data.code,
      });
    }else if(data.code == '401'||data.code == '403'){
      return route.push({
        path: '/login'
      });
    }
    return Promise.reject(
      data.message ||
      "网络错误"
    );
  } else {
    return data;
  }
}, err => {
  let isCancel = axios.isCancel(err);
  if (isCancel) {
    return new Promise(() => {});
  }
  return Promise.reject(
    err.response.data &&
    err.response.data.msg ||
    "网络错误"
  );
})
export function postJson(url, data, otherConfig) {
  return axios.post(url, data, otherConfig);
}

export function post(url, data, otherConfig) {
  return axios.post(url, qs.stringify(data), {
    headers: {
      'Content-Type': "application/x-www-form-urlencoded;charset=UTF-8",
    }
  }, otherConfig);
}

export function get(url, data, otherConfig) {
  return axios.get(url, {
    params: data,
    ...otherConfig
  });
}
export function getGifCode(url, data, otherConfig) {
  return axios.get(url, {
    params: data,
    responseType: 'arraybuffer',
    ...otherConfig
  });
}